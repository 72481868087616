.title-book-section .slick-dots {
    display: none !important;
}

@media screen and (max-width: 991px) {
    .title-book-section .slick-arrow {
        display: none !important;
    }
}

.title-book-sliders .slick-track {
    display: flex !important;
}

.title-book-sliders .slick-slide {
    height: inherit !important;
}

.title-book-sliders .slick-slide > div {
    height: 100% !important;
}
